<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <v-stepper v-model="fase" vertical>
        <v-stepper-step :complete="fase > 1" step="1">
          Upload File
          <small>Files format: xls, csv , xlsx</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-file-input
            v-model="file"
            accept=".xls,.csv,.xlsx"
            show-size
            truncate-length="15"
            label="Select File"
          ></v-file-input>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || file == null"
            @click="firstStep"
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="fase > 2" step="2">
          Data to match
          <small>Select files to match</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <template v-if="headersData.length != 0">
            <v-row class="pa-1">
              <v-col cols="12" sm="6">
                <v-select
                  :items="headersData"
                  v-model="headers.polizeNumber"
                  label="Policy Number *"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="headers.name"
                  label="Client name"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="headers.lastName"
                  label="Client Last Name"
                  outlined
                  clearable
                  dense
                ></v-select>
                <div class="d-flex">
                  <v-select
                    :items="headersData"
                    v-model="headers.month"
                    label="Payment Month"
                    class="mr-1"
                    outlined
                    dense
                  ></v-select>

                  <v-select
                    :items="yearShow"
                    v-model="headers.year"
                    label="Payment Year"
                    outlined
                    class="ml-1"
                    dense
                  ></v-select>
                </div>

                <v-select
                  :items="headersData"
                  v-model="headers.members"
                  label="Members *"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="companies"
                  v-model="headers.companyId"
                  label="Company Id *"
                  :loading="loadingRefer"
                  item-text="name"
                  item-value="id"
                  outlined
                  clearable
                  dense
                ></v-select>
              </v-col>
              <template v-if="firstData != null">
                <v-col class="d-flex flex-column" cols="12" sm="6">
                  <v-text-field
                    label="Policy Number "
                    disabled
                    outlined
                    dense
                    :value="getData(headers.polizeNumber)"
                  ></v-text-field>
                  <v-text-field
                    label="Client Name"
                    disabled
                    outlined
                    dense
                    :value="getData(headers.name)"
                  ></v-text-field>
                  <v-text-field
                    label="Client Last Name"
                    disabled
                    outlined
                    dense
                    :value="getData(headers.lastName)"
                  ></v-text-field>
                  <div class="d-flex">
                    <v-text-field
                      label="Payment Month"
                      disabled
                      class="mr-1"
                      outlined
                      dense
                      :value="getMonth(headers.month)"
                    ></v-text-field>

                    <v-text-field
                      label="Payment Year"
                      disabled
                      outlined
                      class="ml-1"
                      dense
                      :value="getYear(headers.year)"
                    ></v-text-field>
                  </div>

                  <v-text-field
                    label="Members"
                    disabled
                    outlined
                    dense
                    :value="getData(headers.members)"
                  ></v-text-field>
                  <v-text-field
                    label="Company Id"
                    disabled
                    outlined
                    dense
                    :value="getCompanySelect(headers.companyId)"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
            <v-alert outlined type="error" :value="!validMatch || !validMonth">
              {{
                !validMonth
                  ? "Invalid Payment Month"
                  : "Fields with (*) are required"
              }}
            </v-alert>
          </template>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || !validMatch"
            @click="secondStep"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            class="ml-1"
            :disabled="loading"
            @click="resetAll"
          >
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="3" :complete="fase == 3">
          Import successful
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-btn color="primary" @click="resetAll"
            ><v-icon class="mr-1">mdi-cloud-upload</v-icon> import another
          </v-btn>
          <v-btn class="mx-2" color="warning" @click="backclients">
            <v-icon>mdi-exit-to-app</v-icon> Exit
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import cleanObject from "@/utils/cleanObject";
import { notifyError } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import months from "@/utils/enums/months";
import moment from "moment";
import { find } from "lodash";
export default {
  name: "import-chargeback",
  data() {
    return {
      loading: false,
      headersData: [],

      firstData: null,
      lastResult: null,
      configResult: null,
      file: null,
      fase: 1,
      headers: {
        polizeNumber: null,
        name: null,
        lastName: null,
        dob: null,
        month: null,

        members: null,
        companyId: null,
        year: moment().format("YYYY"),
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
    monthShow() {
      return months;
    },

    validMonth() {
      const x = this.headers.month;
      if (x == null || x == undefined || this.firstData == null) {
        return true;
      }
      let temp = this.firstData[x];
      const mon = this.monthShow.find((m) => m.index == temp);
      if (mon) {
        return true;
      }
      return false;
    },
    validDate() {
      let temp = "";
      if (
        this.headers.dob != null &&
        this.firstData[this.headers.dob] != undefined
      ) {
        temp = this.firstData[this.headers.dob].toString();
      }
      if (
        this.headers.dob != null &&
        temp.includes("T") &&
        temp.includes(":") &&
        temp.includes("-") &&
        temp.includes("Z")
      ) {
        return true;
      }
      return false;
    },
    validMatch() {
      if (
        this.headers.members != null &&
        this.headers.polizeNumber != null &&
        this.headers.companyId != null
      ) {
        return true;
      }
      return false;
    },
    yearShow() {
      return [
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
      ];
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    getData(value) {
      return this.firstData[value];
    },
    getMonth(val) {
      let temp = this.firstData[val];
      const x = this.monthShow.find((m) => m.index == temp);

      if (x) {
        return x.name;
      }
      return "Invalid Month";
    },
    getCurrentMonth(val) {
      const x = this.monthShow.find((m) => m.abbreviation == val);
      if (x) {
        return x.name;
      }
      return "Invalid Month";
    },
    getYear(val) {
      const x = this.yearShow.find((m) => m == val);
      if (x) {
        return x;
      }
      return "Invalid Year";
    },

    getDataDob(value) {
      let temp = this.firstData[value];
      if (value == null || temp == undefined) {
        return "Invalid date";
      }
      temp = temp.toString();
      if (
        temp.includes("T") &&
        temp.includes(":") &&
        temp.includes("-") &&
        temp.includes("Z")
      ) {
        const dat = temp.split("T")[0];
        const d = dat.split("-")[2];
        const m = dat.split("-")[1];
        const y = dat.split("-")[0];
        return `${m}/${d}/${y}`;
      }

      return "Invalid date";
    },

    getCompanySelect(value) {
      return this.companies.find((c) => c.id == value)?.name;
    },
    firstStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      getAPI
        .post("/social/decodeFilePreview", formDatafile)
        .then((res) => {
          this.loading = false;
          this.headersData = res.data.headers;
          this.firstData = res.data.first;
          this.fase = 2;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    secondStep() {
      this.loading = true;
      let body = Object.assign({}, this.headers);

      body = cleanObject(body);
      if (body.companyId) {
        body.companyId = Number(body.companyId);
      }

      if (body.year == null) {
        delete body.year;
      } else {
        body.year = Number(body.year);
      }

      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(body).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });

      getAPI
        .post("/task-import/removePayments", formDatafile)
        .then((res) => {
          this.loading = false;
          this.firstResult = res.data.first;
          this.lastResult = res.data.last;
          this.configResult = res.data.config;
          this.fase = 3;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    backclients() {
      this.$router.push("/clients");
    },

    resetAll() {
      this.file = null;
      this.loading = false;
      this.fase = 1;
      this.headersData = [];
      this.firstData = null;
      this.lastResult = null;
      this.configResult = null;

      this.headers = {
        polizeNumber: null,
        name: null,
        lastName: null,
        dob: null,
        month: null,

        members: null,
        companyId: null,
        year: moment().format("YYYY"),
      };
    },
  },
  mounted() {
    this.actListCompaniesNom();
  },
};
</script>
<style lang=""></style>
